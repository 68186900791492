<template>
  <main-layout>
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          lg="10"
        >
          <h2 class="headline mb-3">Huisbezoeken</h2>
          <base-divider
            color="accent"
            dense
          />
          <p>
            {{$t('home_visits.paragraph_one')}}
          </p>
          <p>
            {{$t('home_visits.paragraph_two')}}
          </p>
          <p>Een huisbezoek kan u aanvragen bij voorkeur <strong>tussen 8 en 10 uur ’s morgens</strong>.
            Tijdig aangevraagde huisbezoeken kunnen sneller worden beantwoord. <br><strong>Vermeld bij uw
              aanvraag</strong> steeds:
          </p>
          <ul>
            <li>Naam en voornaam van de patiënt</li>
            <li>Het volledige adres (eventueel de naam op de bel)</li>
            <li>Bondige reden van de aanvraag</li>
            <li>Huisbezoeken “op een afgesproken uur” kunnen niet.</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </main-layout>

</template>

<script>
  import MainLayout from '@/layouts/Main.vue'
  import BaseDivider from '@/components/Divider.vue'

  export default {
    components: {
      MainLayout,
      BaseDivider
    },
    head () {
      return {title: this.$t('huisbezoeken')}
    }
  }
</script>
